export const availableColors = [
    {
        name: 'ballsy-purple',
        value: {
            hex: '#a151c1',
            rgb: '161,81,193'
        }
    },
    {
        name: 'redwine-haze',
        value: {
            hex: '#981a35',
            rgb: '152,26,53'
        }
    },
    {
        name: 'oh-so-green',
        value: {
            hex: '#48a985',
            rgb: '72,169,133'
        }
    },
    {
        name: 'bipolar-grey',
        value: {
            hex: '#cfcdc8',
            rgb: '207,205,200'
        }
    },
    {
        name: 'wommit-yellow',
        value: {
            hex: '#e1d784',
            rgb: '225,215,132'
        }
    },
    {
        name: 'fomo-blue',
        value: {
            hex: '#8cd2df',
            rgb: '140,210,223'
        }
    },
    {
        name: 'shitty-brown',
        value: {
            hex: '#8a501a',
            rgb: '138,80,26'
        }
    },
    {
        name: 'lazy-orange',
        value: {
            hex: '#f1853c',
            rgb: '241,133,60'
        }
    },
    {
        name: 'black-hole',
        value: {
            hex: '#000000',
            rgb: '0,0,0'
        }
    },
    {
        name: 'shady-business',
        value: {
            hex: '#1c4d6e',
            rgb: '28,77,110'
        }
    },
    {
        name: 'nude-ass',
        value: {
            hex: '#f5b6ac',
            rgb: '245,182,172'
        }
    },
    {
        name: 'drunken-piss',
        value: {
            hex: '#f8e53a',
            rgb: '248,229,58'
        }
    }
];

export function getRandomColor(preventColor) {
    const colorsToChooseFrom = availableColors;

    if (preventColor) {
        const index = colorsToChooseFrom.findIndex(object => object.name == preventColor);

        if (index > -1) {
            colorsToChooseFrom.splice(index, 1);
        }
    }

    const randomNumber = Math.floor(Math.random() * colorsToChooseFrom.length);
    return colorsToChooseFrom[randomNumber];
}

export function getColor(colorToGet) {
    return availableColors.find(color => color.name == colorToGet);
}
