import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { onScroll, scrollTop } from '../utils/scroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';
import { getRandomColor } from '../utils/colors';
import { isClientOnMobileOrTablet } from '../utils/navigator';
import Snap from 'snapsvg';

const paths = {
    rect: 'M33,0h41c0,0,0,9.871,0,29.871C74,49.871,74,60,74,60H32.666h-0.125H6c0,0,0-10,0-30S6,0,6,0H33',
    curve: {
        left: 'M33,0h41c0,0-5,9.871-5,29.871C69,49.871,74,60,74,60H32.666h-0.125H6c0,0-5-10-5-30S6,0,6,0H33'
    }
};

const backdrop = document.querySelector('.nav__takeover__backdrop');
// eslint-disable-next-line new-cap
const svg = Snap(backdrop.querySelector('svg'));

export let nav;

export function toggleMenuOpen() {
    if (document.body.classList.contains('nav--open')) {
        disableScrollLock();
        document.body.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }
    } else {
        backdrop.querySelector('svg path').setAttribute('d', paths.curve.left);

        setTimeout(() => {
            svg.select('path').stop()
                .animate({ 'path' : paths.rect }, 1500, mina.elastic);
        }, 350);

        enableScrollLock();
        document.body.classList.add('nav--open');
    }
}

export function setupNav(selector = '.nav', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }
    }

    onScroll(() => {
        if (scrollTop > 200) {
            nav.classList.add('scrolled');
        } else {
            nav.classList.remove('scrolled');
        }
    });

    if (!isClientOnMobileOrTablet()) {
        const primaryNavItems = document.querySelectorAll(`${selector}__menu--primary a`);
        const backdrop = document.querySelector(`${selector}__takeover__backdrop`);
        const navBtn = document.querySelector(`${selector}__btn`);
        const logo = document.querySelector(`${selector}__logo`);

        Array.from(primaryNavItems).forEach(navItem => {
            navItem.addEventListener('mouseenter', event => {
                const color = getRandomColor('black-hole');
                backdrop.style.backgroundColor = color.value.hex;
                backdrop.querySelector('svg path').style.fill = color.value.hex;

                navBtn.classList.add('hover--state');
                logo.classList.add('hover--state');
            });

            navItem.addEventListener('mouseleave', event => {
                backdrop.removeAttribute('style');
                backdrop.querySelector('svg path').removeAttribute('style');
                navBtn.classList.remove('hover--state');
                logo.classList.remove('hover--state');
            });
        });
    }

    window.addEventListener('orientationchange', event => {
        document.querySelector('body').classList.add('is-changing-orientation');

        setTimeout(() => {
            document.querySelector('body').classList.remove('is-changing-orientation');
        }, 500);
    });

    window.addEventListener('resize', event => {
        document.querySelector('body').classList.add('is-resizing');

        setTimeout(() => {
            document.querySelector('body').classList.remove('is-resizing');
        }, 500);
    });
}

export function changeLogoAndBtnColor(color) {
    switch (color) {
        case 'white':
        case 'fff':
        case 'ffffff':
        case '#fff':
        case '#ffffff':
            nav.classList.add('white-nav');
            break;
        default:
            nav.classList.remove('white-nav');
            break;
    }
}
