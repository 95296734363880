import { breakpoints, currentWindowWidth } from '../utils/windowResize';
import Player from '@vimeo/player';

let allCurveSections = null;

export function setupMaskImageAnimation() {
    if(document.getElementsByClassName('will-curve').length > 0) {
        allCurveSections = Array.from(document.getElementsByClassName('will-curve'))
        initCurves()
    }
}

function setupVideoSound(parentModule) {
        const video = parentModule.querySelector('.mask-image__iframe');

        if (video) {
            const player = new Player(video);
            const wrapper = parentModule.querySelector('.mask-image__wrapper');

            wrapper.addEventListener('click', function () {

                player.ready().then(function () {

                    if (parentModule.classList.contains('muted')) {
                        player.setVolume(1);
                        parentModule.classList.remove('muted');

                    } else {
                        player.setVolume(0);
                        parentModule.classList.add('muted');
                    }

                    player.getVolume().then(function (volume) {
                    });
                });
            })
        }
}

function initCurves(){
    for (let s = 0; s < allCurveSections.length; s++) {
        const section = allCurveSections[s].querySelector('.mask-image__wrapper');
        setClipPath(getSectionEntryHeight(section), section)

        setupVideoSound(allCurveSections[s]);

    }
    document.addEventListener("scroll", onScroll);
    
}

function onScroll() {
    for (let s = 0; s < allCurveSections.length; s++) {
        const section = allCurveSections[s].querySelector('.mask-image__wrapper');
        setClipPath(getSectionEntryHeight(section), section)
    }
}

function getSectionEntryHeight(section){
    let sectionRect = section.getBoundingClientRect();
    let entryHeight = window.innerHeight - sectionRect.y
    return pixelToVh(entryHeight);
}

function setClipPath (y_vh, section){
    let styleString;
    let Rx, Ry;
    let Vx, Vy;
    let xPos, yPos;
    
    if (y_vh < 95) {
        xPos = 50;
        yPos = 45 + (y_vh / 5) // clip-pathens y-position som funktion af scroll, så det ser ud som om den titter lige så stille op fra bunden af skærmen "hej-hej, jeg bor hernede"
        // yPos= Math.pow(1.02, Math.abs(y_vh * 2.5));
        // yPos= 60;

        // v = a ^ (skærm-andel / b) 
        // vektorer til at tilpasse elipsekurve-overgangens 
        // "hastighed" som funktion af scrollhøjde. 
        // disse kunne også have været konstante, men synes det virker bedre som funktion af scroll.
        // høj v => mere kurve-udretning pr. scroll.

        
        Vx = Math.pow(1.75, (y_vh  / 60));
        Vy =  Math.pow(1.5, (y_vh  / 40));

        if (section.querySelector('.mask-image__iframe')) {
            Vx = Math.pow(1.75, (y_vh  / 8));

            if (currentWindowWidth >= (breakpoints['md'])) {
                Vx = Math.pow(1.75, (y_vh  / 60));
            }
        }

        Rx = calculateRadius(section, y_vh, 60, Vx, 500, 1000) 
        Ry = calculateRadius(section, y_vh, 50, Vy, yPos, yPos) // hvis y-radius > y-position kan kurven se lidt afskårn' ud¨

        styleString = 'ellipse('+ Rx + '% '+ Ry  +'% at '+ xPos +'% '+ yPos +'%)';


        // // alternativt, en simplere ellipse-radius-funktion, kun sat af x-radius. 
        // let Rxn = 30 + (y_vh * (y_vh / 12.5));
        // styleString = 'ellipse('+ Rxn + '% 50% at 50% 50%)';

    } else {

        styleString = 'none'; // fjern kurven når vi er forbi sektionen. 
    } 

    section.style.clipPath = styleString
}

function calculateRadius(section, screenPc, c, vector = 1, thresholdValue, thresholdAlt) {


    // Er det muligt at fange section.querySelector('.mask-image__iframe') her????
    
    screenPc = screenPc * vector
    c = 80

    if (currentWindowWidth >= (breakpoints['md'])) {
        c = 70
    }

    if (section.querySelector('.mask-image__iframe')) {
        c = 70
    }

    // nedadvendt parabel
    // r = ( xv^2 / a) + c
    // skru på c for at ændre kurvens udgangspunkt/start
    let radius = (screenPc * (screenPc / 150) ) + c; 
    
    if (thresholdValue && thresholdAlt && radius > thresholdValue) {
        // sæt radius til et tal for at kunne css-transition'e (i stedet for "none")
        radius = thresholdAlt
    }
    
    return radius
}

// grænseværdier udregnet til viewheight så effekten virker på enheder af alle størrelser.
function pixelToVh(px) {
    return px * (100 / document.documentElement.clientHeight);
}
function pixelToVw(px) {
    return px * (100 / document.documentElement.clientWidth);
}

