import { getRandomColor } from './colors';

const circlePath = 'M 0, 0 m -100, 0 a 100,100 0 1,0 200,0 a 100,100 0 1,0 -200,0';

let cursor, svg, action, blob, resetTimer;

export function setupCustomCursor() {
    document.body.classList.add('has-custom-cursor');

    cursor = document.createElement('DIV');
    action = document.createElement('DIV');
    svg = createSvg();

    cursor.classList.add('cursor');
    action.classList.add('cursor__action');
    svg.classList.add('cursor__svg');

    cursor.append(svg);
    cursor.append(action);

    document.body.append(cursor);

    blob = document.getElementById('blob');

    document.addEventListener('mouseleave', () => {
        document.body.classList.add('cursor-has-left-the-building');
    });

    document.addEventListener('mouseenter', () => {
        document.body.classList.remove('cursor-has-left-the-building');
    });

    document.addEventListener('mousemove', moveCursor);

    const blobTriggers = document.querySelectorAll('[data-blob]');
    const colorTriggers = document.querySelectorAll('[data-cursor-color]');
    const blendTriggers = document.querySelectorAll('[data-cursor-blend]');

    Array.from(blobTriggers).forEach(trigger => {
        trigger.addEventListener('mouseenter', event => {
            clearTimeout(resetTimer);

            const colorToPrevent = trigger.getAttribute('data-prevent-blob-color');
            const action = trigger.getAttribute('data-blob');
            let scale = 6;

            if (trigger.hasAttribute('data-blob-scale')) {
                scale = trigger.getAttribute('data-blob-scale');
            }

            morph(colorToPrevent, scale);
            setAction(action);
        });

        trigger.addEventListener('mouseleave', resetBlob);

        if (trigger.hasAttribute('data-toggle-blob-action')) {
            trigger.addEventListener('click', event => {
                const oldAction = trigger.getAttribute('data-blob');

                trigger.setAttribute('data-blob', trigger.getAttribute('data-toggle-blob-action'));
                trigger.setAttribute('data-toggle-blob-action', oldAction);


                if(trigger.hasAttribute('data-prevent-reset')) {
                    setAction(trigger.getAttribute('data-blob'));
                } else {
                    resetBlob();
                }
            });
        }

        if (trigger.hasAttribute('data-toggle-prevent-blob-color')) {
            trigger.addEventListener('click', event => {
                const oldColor = trigger.getAttribute('data-prevent-blob-color');

                trigger.setAttribute('data-prevent-blob-color', trigger.getAttribute('data-toggle-prevent-blob-color'));
                trigger.setAttribute('data-toggle-prevent-blob-color', oldColor);

                resetBlob();
            });
        }
    });

    Array.from(colorTriggers).forEach(trigger => {
        trigger.addEventListener('mouseenter', event => {
            const color = event.target.getAttribute('data-cursor-color');
            changeCursorColor(color);
        });

        trigger.addEventListener('mouseleave', clearCursorColor);
    });

    Array.from(blendTriggers).forEach(trigger => {
        trigger.addEventListener('mouseenter', event => {
            const blendMode = event.target.getAttribute('data-cursor-blend');
            changeCursorBlend(blendMode);
        });

        trigger.addEventListener('mouseleave', clearCursorBlend);
    });
}

function createSvg() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    path.setAttribute('d', circlePath);
    path.setAttribute('transform', 'translate(100, 100)');
    path.setAttribute('id', 'blob');

    svg.setAttribute('viewBox', '0 0 200 200');
    svg.append(path);

    return svg;
}

function moveCursor(event) {
    const x = event.clientX;
    const y = event.clientY;

    cursor.style.left = `${x}px`;
    cursor.style.top = `${y}px`;
}

function getRandomblob() {
    const blobs = [
        'M43.5,-75.6C57.4,-67.3,70.5,-57.8,79.6,-45C88.8,-32.2,94,-16.1,93.1,-0.5C92.2,15,85.2,30.1,76.3,43.3C67.3,56.4,56.5,67.8,43.5,76.1C30.5,84.4,15.2,89.6,-0.1,89.9C-15.5,90.1,-31,85.4,-44.1,77.1C-57.2,68.9,-67.8,57.1,-75.9,43.6C-83.9,30.2,-89.4,15.1,-89.7,-0.2C-90,-15.4,-85.1,-30.9,-76.9,-44.2C-68.8,-57.5,-57.4,-68.6,-44.1,-77.2C-30.7,-85.8,-15.4,-91.7,-0.3,-91.2C14.8,-90.8,29.6,-83.8,43.5,-75.6Z',
        'M44.7,-77C59.1,-69.2,72.8,-59.5,81.7,-46.4C90.7,-33.3,95.1,-16.6,94.8,-0.2C94.5,16.3,89.6,32.6,81.2,46.7C72.8,60.8,60.8,72.7,46.7,80.6C32.6,88.5,16.3,92.5,0.4,91.8C-15.5,91.1,-31,85.8,-43.7,77.1C-56.4,68.4,-66.3,56.2,-74,42.8C-81.7,29.4,-87.2,14.7,-87.6,-0.3C-88.1,-15.2,-83.6,-30.5,-75.7,-43.4C-67.7,-56.4,-56.3,-67.1,-43.2,-75.6C-30,-84.1,-15,-90.4,0.1,-90.6C15.2,-90.7,30.3,-84.8,44.7,-77Z',
        'M43.6,-74.4C57.4,-67.5,70.2,-57.7,77.5,-44.8C84.8,-31.9,86.6,-16,86.1,-0.3C85.6,15.4,82.9,30.9,76,44.5C69.2,58.1,58.2,69.9,44.8,78.4C31.4,86.9,15.7,92.2,0,92.2C-15.7,92.1,-31.3,86.7,-45.5,78.7C-59.7,70.6,-72.5,59.9,-79.8,46.4C-87.1,32.8,-89,16.4,-87.6,0.8C-86.3,-14.9,-81.8,-29.7,-74.7,-43.7C-67.6,-57.6,-57.9,-70.6,-45.1,-78.1C-32.2,-85.5,-16.1,-87.4,-0.6,-86.4C14.9,-85.3,29.7,-81.3,43.6,-74.4Z',
        'M43.3,-75.1C56.9,-67.2,69.2,-57.1,77.4,-44.2C85.7,-31.4,90,-15.7,90.6,0.3C91.2,16.4,88.1,32.8,80.1,46C72.1,59.3,59,69.5,44.9,76.6C30.7,83.7,15.3,87.8,0.1,87.6C-15.2,87.5,-30.3,83.1,-43.7,75.5C-57.1,67.9,-68.6,57.1,-76.2,44C-83.8,30.9,-87.4,15.4,-87.3,0C-87.3,-15.4,-83.6,-30.7,-75.8,-43.5C-68,-56.3,-56.2,-66.5,-42.8,-74.6C-29.5,-82.8,-14.8,-88.8,0,-88.9C14.9,-89,29.7,-83.1,43.3,-75.1Z',
        'M45.9,-80.3C59.5,-71.6,70.6,-59.4,78.2,-45.4C85.8,-31.5,89.9,-15.7,90.4,0.3C90.9,16.3,87.9,32.7,79.7,45.6C71.5,58.6,58.2,68.1,44,76.3C29.9,84.4,15,91.1,-0.4,91.9C-15.8,92.7,-31.7,87.5,-45.1,78.9C-58.5,70.4,-69.5,58.4,-77.4,44.7C-85.3,31,-90.2,15.5,-90.4,-0.1C-90.6,-15.7,-86.1,-31.4,-77.9,-44.6C-69.7,-57.8,-57.7,-68.5,-44.1,-77.2C-30.5,-85.9,-15.2,-92.7,0.4,-93.5C16.1,-94.3,32.2,-89,45.9,-80.3Z',
    ];
    const random = Math.floor(Math.random() * blobs.length);

    return blobs[random];
}

function changeCursorColor(color) {
    blob.style.fill = color;
}

function changeCursorBlend(blend) {
    cursor.style.mixBlendMode = blend;
}

function clearCursorColor() {
    blob.style.removeProperty('fill');
}

function clearCursorBlend() {
    cursor.style.removeProperty('mix-blend-mode');
}

export function resetBlob() {
    svg.style.transform = 'translate(-50%,-50%)';

    action.classList.remove('show-action');

    cursor.className = 'cursor';

    resetTimer = setTimeout(() => {
        blob.setAttribute('d', circlePath);
        action.innerText = '';
    }, 600);
}

export function morph(colorToPrevent = null, scale = 6) {
    blob.setAttribute('d', getRandomblob());
    cursor.classList.add(getRandomColor(colorToPrevent).name);

    svg.style.transform = `translate(-50%,-50%) scale(${scale})`;
}

export function setAction(actionName) {
    action.innerHTML = actionName;
    action.classList.add('show-action');
}
