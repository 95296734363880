import { getColor } from './colors';

const BG = document.createElement('DIV');
let targets;

export function setupGlobalBackground() {
    BG.classList.add('global-background');
    document.body.prepend(BG);

    targets = document.querySelectorAll('[data-bg-color]');

    if (targets.length > 0) {
        Array.from(targets).forEach(watchForBgChange);

        setInitColor();
    } else {
        BG.remove();
    }
}

function changeBG(colorName) {
    const colorCode = getColor(colorName) ? getColor(colorName).value.hex : '';
    const altFillItems = document.querySelectorAll('.follow-fill');
    const altBorderItems = document.querySelectorAll('.follow-border');

    if (colorName == 'black-hole') {
        document.body.classList.add('text--white');
    } else {
        document.body.classList.remove('text--white');
    }

    BG.style.backgroundColor = colorCode;

    Array.from(altFillItems).forEach(item => {
        item.style.fill = colorCode;
    });

    Array.from(altBorderItems).forEach(item => {
        item.style.borderColor = colorCode;
    });
}

function setInitColor() {
    const initColor = Array.from(targets)[0].getAttribute('data-bg-color');
    changeBG(initColor);
    BG.classList.add('ready');
}

function watchForBgChange(module) {
    const options = {
        threshold: 0.1
    };

    const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const colorName = entry.target.getAttribute('data-bg-color');
            if (entry.isIntersecting) {
                if (BG.classList.contains('ready')) {
                    changeBG(colorName);
                }
            }
        });
    }, options);

    io.observe(module);
}
