import * as ScrollMagic from 'scrollmagic';
import { gsap, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

gsap.config({
    nullTargetWarn: false
});
gsap.registerPlugin(TimelineMax);
// eslint-disable-next-line new-cap
ScrollMagicPluginGsap(ScrollMagic, TimelineMax);

import { onWindowResize } from './utils/windowResize';
import { onReady } from './utils/onReady';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupAnchorLinkScroll } from './components/anchors';
import { setupGlobalBackground } from './utils/globalBackground';
import { setupIntersectionObserver } from './utils/intersection';
import { setupHeaderDefault } from './animations/headerDefault';
import { setupHeaderWithImageVideo, calculateHeaderVideoSize} from './animations/headerWithImageVideo';
import { setupStatementAnimation } from './animations/statement';
import { setupMovingTextAnimation } from './animations/movingText';
import { setupLogoToImageAnimation } from './animations/logoToImageReveal';
import { setupMaskImageAnimation } from './animations/maskImage';
import { setupNextPageAnimation } from './animations/nextPage';
import { setupLogoNavWatcher } from './utils/logoNavWatcher';
import { setupButtonAnimation } from './animations/btn';
import { canHover } from './utils/navigator';
import { setupCustomCursor } from './utils/cursor';

function init() {
    document.body.classList.remove('standby');

    // Detect touch
    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    setupGlobalBackground();
    setupIntersectionObserver();
    setupLogoNavWatcher();

    if (canHover) {
        setupCustomCursor();
    }

    onReady(() => {
        initVhUnitOverwrite(calculateHeaderVideoSize);
        setupNav();
        setupLazyLoading();
        setupAnchorLinkScroll();
        setupHeaderDefault();
        setupHeaderWithImageVideo();
        setupStatementAnimation();
        setupMovingTextAnimation();
        setupLogoToImageAnimation();
        setupMaskImageAnimation();
        setupNextPageAnimation();
        setupButtonAnimation();
    });

    onWindowResize(() => {
        calculateHeaderVideoSize();
    });
}

init();
