import * as ScrollMagic from 'scrollmagic';
import { TimelineMax } from 'gsap';

export function setupHeaderDefault() {
    const target = document.querySelector('.header--default');

    if (target) {
        animateHeader(target);
    }
}

function animateHeader(target) {
    const scrollController = new ScrollMagic.Controller();

    const targets = {
        inner: target.querySelector('picture'),
        title: target.querySelector('h1'),
        text: target.querySelector('.header__text'),
        link: target.querySelector('.header__link'),
        mask: target.querySelector('.header__mask')
    };

    const timeline = new TimelineMax();
    timeline
        .to(targets.inner, 3, { x: '0' })
        .to(targets.title, 3, { y: '-100px'}, '-=3')
        .to(targets.text, 3, { y: '-100px'}, '-=3')
        .to(targets.link, 3, { y: '-100px'}, '-=3')
        .to(targets.mask, 3, { x: '0' }, '-=3');

    const scrollScene = new ScrollMagic.Scene({
        triggerHook: 0,
        triggerElement: target,
        duration: '100%',
    }).setTween(timeline)
        .addTo(scrollController);
}
