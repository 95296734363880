import * as ScrollMagic from 'scrollmagic';
import { TimelineMax, Power1 } from 'gsap';
import { getElementSize } from '../utils/elementProperties';

export function setupNextPageAnimation() {
    const container = document.querySelector('.next-page');

    if (container) {
        animateLoading(container);
    }
}

let overlay, link, loaderWrap, loader, image, imageShadow, redirect;

function animateLoading(container) {
    overlay = container.querySelector('.next-page__overlay'),
    link = container.querySelector('.next-page__link'),
    loaderWrap = container.querySelector('.next-page__loader-wrap'),
    loader = container.querySelector('.next-page__loader'),
    image = container.querySelector('.next-page__image-wrap img'),
    imageShadow = container.querySelector('.next-page__image-shadow');

    const loaderWidth = getElementSize(loaderWrap).width;
    const overlayPadding = parseInt(window.getComputedStyle(overlay, null).getPropertyValue('padding-top'));
    const halfDown = (getElementSize(overlay).height - getElementSize(link).height) / 2 - overlayPadding;

    const scrollController = new ScrollMagic.Controller();
    const scrollAnimation = new TimelineMax();

    scrollAnimation
        .to(loader, 1, { width: loaderWidth })
        .to(link, 1, { y: halfDown }, '-=1')
        .to(image, 1, { scale: 1.2 }, '-=1')
        .to(imageShadow, 1, { opacity: 0 }, '-=1');

    const scrollScene = new ScrollMagic.Scene({
        triggerHook: 0.75,
        triggerElement: container,
        duration: '70%'
    })
        .setTween(scrollAnimation)
        .addTo(scrollController)
        .on('end', endAnimation);
}

function endAnimation(event) {

    if (event.progress == 1) {
        redirect = setTimeout(() => {
            const finishAnimation = new TimelineMax();
            const fullWidth = getElementSize(loaderWrap).width;

            finishAnimation
                .to(loader, 1, { width: fullWidth })
                .to(loaderWrap, 2, { width: 0, ease: Power1.easeOut });

            setTimeout(() => {
                link.click();
            }, 2000);

        }, 0);
    } else {
        clearTimeout(redirect);
    }
}
