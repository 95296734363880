import * as ScrollMagic from 'scrollmagic';
import { TimelineMax } from 'gsap';
import { getElementSize } from '../utils/elementProperties';
import { breakpoints, currentWindowWidth } from '../utils/windowResize';

export function setupMovingTextAnimation() {
    const containers = document.querySelectorAll('.moving-text');

    if (currentWindowWidth >= (breakpoints['md'])) {
        Array.from(containers).forEach(container => {
            setTimeout(() => {
                animateMovingText(container); // Timeout needed to avoid miscalculations upon pageload
            }, 0);
        });
    }
}

function animateMovingText(container) {
    const innerContainer = container.querySelector('.moving-text__content'),
        movingText = container.querySelector('.moving-text__mover'),
        lastLine = container.querySelector('.moving-text__last-line'),
        lastLineSizeHeight = getElementSize(lastLine).height,
        containerHeight = getElementSize(innerContainer).height,
        movingSizeWidth = getElementSize(movingText).width,
        yDistance = containerHeight - lastLineSizeHeight,
        xDistance = movingSizeWidth;

    const scrollController = new ScrollMagic.Controller();
    const timeLine = new TimelineMax();

    timeLine
        .from(movingText, 1, {y: -300})
        .to(movingText, 6, {y: yDistance }, '+=3')
        .to(movingText, 3, {x: xDistance, marginRight: 0 })
        .to(lastLine, 3, {x: xDistance}, '-=3');

    const scrollScene = new ScrollMagic.Scene({
        triggerHook: 0,
        triggerElement: container,
        duration: '200%'
    })
        .setTween(timeLine)
        .setPin(container)
        .addTo(scrollController);
}
