import * as ScrollMagic from 'scrollmagic';
import { TimelineMax } from 'gsap';
import { getElementSize } from '../utils/elementProperties';

export function setupStatementAnimation() {
    const targets = document.querySelectorAll('.statement');

    Array.from(targets).forEach(target => {
        animateStatement(target);
    });
}

function animateStatement(target) {
    const elementHeight = parseInt(getElementSize(target).height);

    const container = target.querySelector('.statement__inner');
    const firstLine = target.querySelector('.statement__first-line');
    const secondLine = target.querySelector('.statement__second-line');
    const inner = secondLine.querySelector('.inner');

    const scrollController = new ScrollMagic.Controller();
    const timeLine = new TimelineMax();

    timeLine
        .from(firstLine, 0.5, { scale: 0.615 })
        .from(secondLine, 1, {height: 0})
        .from(inner, 1, { rotateX: 90}, '-=1')
        .to({}, 1, {});

    const scrollScene = new ScrollMagic.Scene({
        triggerHook: 0,
        triggerElement: target,
        duration: elementHeight
    })
        .setTween(timeLine)
        .setPin(target)
        .addTo(scrollController);

    scrollScene.on('update', event => {
        const position = event.scrollPos - event.endPos;

        if (position > 0 && position < window.innerHeight) {
            container.style.transform = `translate3d(0,${position}px,0)`;
        } else {
            container.style.transform = 'translate3d(0,0,0)';
        }
    });
}
