import Player from '@vimeo/player';
import * as ScrollMagic from 'scrollmagic';
import { TimelineMax, Power1 } from 'gsap';

function setHeaderVideoSize(header, video) {
    const headerWidth = header.clientWidth;
    const headerHeight = header.clientHeight;
    const videoHeight = headerWidth * .5625;

    video.style.width = null;
    video.style.height = `${Math.ceil(videoHeight)}px`;

    if (headerHeight > videoHeight) {
        const videoWidth = headerHeight * 1.777777777777778;

        video.style.height = null;
        video.style.width = `${Math.ceil(videoWidth)}px`;
    }
}

function animateHeader(header) {
    const target = header.querySelector('img');

    const initAnimation = new TimelineMax();
    initAnimation.fromTo(target, {scale: 1.5, rotate: -3}, {scale: 1.2, rotate: 0, duration: 1, ease: Power1.easeOut});

    const scrollController = new ScrollMagic.Controller();
    const scrollAnimation = new TimelineMax();

    scrollAnimation.fromTo(target, {scale: 1.2}, {scale: 1, duration: 1, ease: Power1.easeOut});

    const scrollScene = new ScrollMagic.Scene({
        triggerHook: 0,
        triggerElement: header,
        duration: '100%',
    }).setTween(scrollAnimation)
        .addTo(scrollController);
}

export function calculateHeaderVideoSize() {
    const header = document.querySelector('.header--with-image-video');

    if (header) {
        const headerVideo = header.querySelector('iframe');

        if (headerVideo) {
            const headerHeight = header.clientHeight;

            setHeaderVideoSize(header, headerVideo);

            document.onreadystatechange = () => {
                if (document.readyState === 'complete' && header.clientHeight !== headerHeight) {
                    setHeaderVideoSize(header, headerVideo);
                }
            };
        }
    }
}

export function setupHeaderWithImageVideo() {
    const header = document.querySelector('.header--with-image-video');

    if (header) {
        animateHeader(header);

        const headerVideo = header.querySelector('iframe');

        if (headerVideo) {
            const player = new Player(headerVideo);
            player.on('play', function() {
                header.classList.add('video--playing');
            });
        }
    }
}
