import * as ScrollMagic from 'scrollmagic';
import { TimelineMax } from 'gsap';

export function setupLogoToImageAnimation() {
    const targets = document.querySelectorAll('.logo-to-image-reveal__inner');

    Array.from(targets).forEach(target => {
        animateLogoToImage(target);
    });
}

function animateLogoToImage(target) {
    const scrollController = new ScrollMagic.Controller();
    const timeLine = new TimelineMax();

    const logo = target.querySelector('.logo-to-image-reveal__logo');
    const overlay = target.querySelector('.logo-to-image-reveal__background-overlay');
    const text = target.querySelector('.logo-to-image-reveal__background-text .inner');
    const link = target.querySelector('a');

    timeLine.to(logo, 4, {scale: 31, x: '-50%'});

    const scrollScene = new ScrollMagic.Scene({
        triggerHook: 0,
        triggerElement: target,
        duration: '200%'
    })
        .setTween(timeLine)
        .setPin(target)
        .addTo(scrollController)
        .on('end', event => {
            if (event.state === 'AFTER') {
                target.classList.add('finito');
            } else {
                target.classList.remove('finito');
            }
        });
}
