export function setupButtonAnimation() {
    const targets = document.querySelectorAll('.btn__circle');

    if (targets && targets.length > 0) {
        Array.from(targets).forEach(watchForIntersect);
    }
}

function watchForIntersect(target) {
    const options = {
        threshold: 0.5
    };

    const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('trigger');
            }
        });
    }, options);

    io.observe(target);
}
