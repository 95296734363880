export function setupLogoNavWatcher() {
    const nav = document.querySelector('.nav');
    const header = document.querySelector('.header');

    if (header && header.classList.contains('text--white')) {
        const options = {
            threshold: [0.1, 1]
        };

        const io = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                nav.classList.toggle('text--white');
            });
        }, options);

        io.observe(header);
    }
}
